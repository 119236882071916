<template>
  <div class="page">
    <Viewheader theme="light" :isHome="false" ref="navheader" />

    <!-- banner图 -->
    <BannerHead :title="bigTitle_1" :desc="smallTitle_1">
      <div class="header-custom" slot="content">
        <img class="banner-bg" :src="bannerImg" />
        <!-- <div class="banner-imgs">
          <img
            class="banner1"
            src="@/assets/img/brandIdentify/banner-card.png"
          />
        </div> -->
      </div>
    </BannerHead>

    <!-- 核心功能 -->
    <div class="core-function">
      <CommonTitle title="核心功能" />
      <img src="@/assets/img/brandIdentify/core-1.png" class="core-scan" />
      <ColumuTwo class="core-function_main" :list="spanList"></ColumuTwo>
      <div class="core-custom">
        <img class="core-clothes" src="@/assets/img/brandIdentify/core-2.png" />
        <img class="core-clothes" src="@/assets/img/brandIdentify/core-3.png" />
        <img class="core-person" src="@/assets/img/brandIdentify/core-4.png" />
        <img class="core-similar" src="@/assets/img/brandIdentify/core-5.png" />
        <img class="core-store" src="@/assets/img/brandIdentify/core-6.png" />
      </div>
    </div>

    <!-- 能力展示 -->
    <div class="similar-main-wrap">
      <div class="similar-main">
        <CommonTitle title="能力展示" :desc="ability.desc" />
        <div class="operationWidth similar-main-cont">
          <div class="operationBackgroundColor">
            <!-- 之前没有做图片上传逻辑 -->
            <!-- @success="getResultImg" -->
            <!-- @change="handleChange" -->
            <ImgUpload
              class="sc-img-upload"
              @select="selectPicture"
              :modelIndex.sync="imgCurrentActive"
              :imgList="exampleDiagram"
              :action="action"
              :directionRow1280="true"
              :showUpload="false"
              accept="image/jpeg,image/jpg,image/png"
              uploadText="选择一张"
            />
            <div class="operationCenter">
              <!-- 成功图片 -->
              <div v-if="successImg" class="successImgProduct">
                <div class="flatRecognitionPositioning">
                  <img :src="this.imgUrl" ref="image" />
                  <div v-for="(item, index) in resultData" :key="index">
                    <div
                      :style="{
                        width: `${item.width}px`,
                        height: `${item.height}px`,
                        position: `absolute`,
                        top: `${item.top}px`,
                        left: `${item.left}px`,
                      }"
                      class="lineBorder"
                      v-if="xyPos"
                    ></div>
                  </div>
                </div>
              </div>
              <!-- 报错图片 -->
              <div class="errMain" v-if="errImg">
                <div class="errImg">
                  <img src="../../assets/img/err.png" />
                </div>
                <div class="errText">
                  {{ errText }}
                </div>
              </div>

              <!-- 加载特效 -->
              <div class="loadSpecialEffects" v-if="loadImg"></div>

              <!-- 扫描脚框 -->
              <div class="top" v-if="loadImg"></div>
              <div class="bottom" v-if="loadImg"></div>
            </div>
            <div class="operationYellowRight">
              <div class="recognitionResult">识别结果：</div>
              <div
                class="brandsResult"
                v-for="(item, index) in resultData"
                :key="index"
              >
                <div class="confidenceLevel">
                  相似度：{{ Number(item.score * 100).toFixed(2) + '%' }}
                </div>
                <div class="confidenceLevel">品牌：{{ item.brand }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 应用场景 -->
    <div class="application-scene">
      <CommonTitle title="应用场景" />
      <ColumuTwo
        class="application-scene_main"
        :list="appliList"
        :img="appImg"
      ></ColumuTwo>
    </div>

    <!-- 尾部 -->
    <Footering />
  </div>
</template>

<script>
// banner
import BannerHead from './components/Banner.vue';
import CommonTitle from './components/CommonTitle.vue';
import ColumuTwo from './components/ColumuTwo.vue';
import Different from '@/components/centerPage/differential_adv.vue';
// 上传图片列表组件
import ImgUpload from '@/components/secondPage/imgUpload.vue';

import app1 from '@/assets/img/brandIdentify/app-1.png';
import googleLogo from '@/assets/img/imgSimilar/google.png';

// 头部
import Viewheader from '../../components/viewheader/viewheader.vue';
// foote组件
import Footering from '@/components/foot/footer.vue';
import { apiUri } from '../../api/txt';
import { infringement } from '@/api/aidata.js';
// 公用组件
import '../../assets/css/public.css';
import '../../assets/css/imgPublic.css';
import '../../assets/css/imgPublic.css';
import '../../assets/css/commonModule.css';
export default {
  components: {
    BannerHead,
    CommonTitle,
    ColumuTwo,
    Different,
    Viewheader,
    Footering,
    ImgUpload,
  },
  data() {
    return {
      // bannerImg: require('@/assets/img/brandIdentify/banner.png'),
      bannerImg: require('@/assets/img/brandIdentify/banner-1.png'),
      bigTitle_1: '<span style="color:#fff;">自建库特定元素识别</span>',
      smallTitle_1:
        '<span style="color:#fff;">支持自定义商品图元素建库。对需要识别的商品图进行元素检测并与样本库进行对比。返回商品图元素信息。</span>',

      // 核心功能
      spanList: [
        {
          title: '跨境电商领域特定元素检测',
          desc: '基本支持跨境电商常见特定元素的检测，可不限定元素检测/识别范围。返回图片中的所有特定元素位置、元素小图及置信度分值。',
        },
        {
          title: '自定义元素信息库',
          desc: ' 领域数据集自建库，自定义元素小图的详细信息（如 出处、国家地区、风险等级等）',
        },
      ],
      // 能力展示
      ability: {
        desc: `模型结果对<img src='${googleLogo}' /> <span style='color:#6C38E0'>google 系列产品（shopping/⾕歌seo等）</span>，进⾏了针对性优化，在此场景下使⽤效果更优。模型⽬前仅⽀持png、jpg、jpeg。`,
      },

      //   差异优势
      diffList: [
        {
          title: '通用品牌识别',
          desc: '通用品牌识别通用品牌识别通用品牌识别通用品牌识别通用品牌识别',
        },
        {
          title: '通用品牌识别',
          desc: '通用品牌识别 通用品牌识别通用品牌识别通用品牌识别',
        },
      ],

      //   应用场景
      appImg: app1,
      appliList: [
        {
          title: '电商平台/渠道投放准入',
          desc: '识别商品特定元素，实现平台/渠道投放商品准入及管控。',
        },
        {
          title: '商品理解',
          desc: '提升平台商品理解能力，实现商品更精准的分发。如基于“自建样本库”进行标签打标，实现用户标签偏好分析、商品定向投放引流等；',
        },
      ],

      // 能力展示
      // header请求
      action: `${apiUri}/img/infringement`,
      imgUrl: '',
      conf: '',
      result: '',
      headers: {
        token: localStorage.getItem('tokenKey')
          ? localStorage.getItem('tokenKey')
          : '',
        isSample: 1,
      },

      // 示例图列表
      exampleDiagram: [
        {
          img: 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/file/haier1_boxes.jpg',
        },
        {
          img: 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/file/huawei_boxes.jpg',
        },
        {
          img: 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/file/maotai_boxes.png',
        },
        {
          img: 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/file/midea2_boxes.jpg',
        },
        {
          img: 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/file/tesla_boxes.jpg',
        },
      ],
      // 初始化选中状态
      imgCurrentActive: 0,
      // 初始化选中图
      resultGraphImg:
        'https://ai-image.s3.cn-north-1.amazonaws.com.cn/file/haier1_boxes.jpg',
      // 成功图
      successImg: false,
      // 失败图
      errImg: true,
      // 正在加载
      loadImg: true,
      // 加载文案
      errText: '处理中....',
      urlAdress: '',
      // 加载时，禁止点击
      prohibitClicking: false,
      // 优化后按钮
      afterOptimization: false,
      brandsClassName: '',
      brandsConfidence: '',
      // 边框宽
      lineWidth: '',
      // 边框高
      lineHeight: '',
      // 上距离
      lineTop: '',
      // 左距离
      lineLeft: '',
      xyPos: false,
      isSample: '',
      resultData: '',
      resultDataList: [
        [
          {
            coordinate: { ymin: 86, xmin: 442, ymax: 156, xmax: 570 },
            itemCode: '6876420513430891002',
            brand: 'Haier_text',
            score: 0.9001,
            ctr30d: 0,
            brandModel: 0,
            id: 0,
            category: null,
            imgType: 0,
          },
          {
            coordinate: { ymin: 58, xmin: 852, ymax: 136, xmax: 939 },
            itemCode: '8297620907775063289',
            brand: 'Haier_text',
            score: 0.82,
            ctr30d: 0,
            brandModel: 0,
            id: 0,
            category: null,
            imgType: 0,
          },
          {
            coordinate: { ymin: 360, xmin: 746, ymax: 381, xmax: 810 },
            itemCode: '-7537309077896808762',
            brand: 'Haier_text',
            score: 0.7953,
            ctr30d: 0,
            brandModel: 0,
            id: 0,
            category: null,
            imgType: 0,
          },
        ],
        [
          {
            coordinate: { ymin: 86, xmin: 442, ymax: 156, xmax: 570 },
            itemCode: '6876420513430891002',
            brand: 'Huawei_pattern',
            score: 0.9133,
            ctr30d: 0,
            brandModel: 0,
            id: 0,
            category: null,
            imgType: 0,
          },
          {
            coordinate: { ymin: 58, xmin: 852, ymax: 136, xmax: 939 },
            itemCode: '8297620907775063289',
            brand: 'Huawei_pattern',
            score: 0.871,
            ctr30d: 0,
            brandModel: 0,
            id: 0,
            category: null,
            imgType: 0,
          },
        ],
        [
          {
            coordinate: { ymin: 86, xmin: 442, ymax: 156, xmax: 570 },
            itemCode: '6876420513430891002',
            brand: 'Kweichow Moutai',
            score: 0.8582,
            ctr30d: 0,
            brandModel: 0,
            id: 0,
            category: null,
            imgType: 0,
          },
        ],
        [
          {
            coordinate: { ymin: 86, xmin: 442, ymax: 156, xmax: 570 },
            itemCode: '6876420513430891002',
            brand: 'Midea_1',
            score: 0.953,
            ctr30d: 0,
            brandModel: 0,
            id: 0,
            category: null,
            imgType: 0,
          },
        ],
        [
          {
            coordinate: { ymin: 86, xmin: 442, ymax: 156, xmax: 570 },
            itemCode: '6876420513430891002',
            brand: 'Tesla_car',
            score: 0.897,
            ctr30d: 0,
            brandModel: 0,
            id: 0,
            category: null,
            imgType: 0,
          },
        ],
      ],
      reductionFactor: '',
    };
  },
  watch: {
    imgCurrentActive: {
      immediate: true,
      handler(val) {
        // this.resultData = this.resultDataList[this.imgCurrentActive]
        this.resultData = [];
      },
    },
  },
  computed: {},
  mounted() {
    this.$nextTick(() => {
      // 默认点击高亮
      this.selectPicture(this.imgCurrentActive, { img: this.resultGraphImg });
    });
  },
  methods: {
    // 点击选中高亮
    selectPicture(index, { img }) {
      this.resultGraphImg = img;
      this.imgCurrentActive = index;
      this.infringement(0, this.resultGraphImg);
    },
    // 通用logo  请求
    infringement(status, img) {
      console.log(img);
      // 成功图
      this.successImg = false;
      // 失败图
      this.errImg = true;
      // 正在加载
      this.loadImg = true;
      this.errText = '处理中....';

      if (status == 0) {
        // 点击图片获取地址
        var imgUrl = img;
        // 加载时禁止点击切换
        this.prohibitClicking = true;
        this.isSample = 0;
      } else {
        // 点击检测按钮  获取地址
        var imgUrl = this.urlAdress;
        this.imgCurrentActive = -1;
        this.isSample = 1;
      }

      // 请求接口-------------------
      infringement({
        imgUrl: imgUrl,
        isSample: this.isSample,
      }).then(({ data }) => {
        if (data.state === '0x0000') {
          setTimeout(() => {
            this.urlAdress = '';
            // 成功图
            this.successImg = true;
            this.imgUrl = data.data.url;

            var _this = this;
            var img = new Image();
            img.src = data.data.url;
            // 计算边框位置
            img.onload = function () {
              // console.log(data.data.result[0])
              if (data.data.result[0]) {
                for (var i = 0; i < data.data.result[0].length; i++) {
                  var reductionFactor =
                    _this.$refs.image.offsetWidth / img.width; //缩小倍数
                  var width =
                    (data.data.result[0][i].coordinate.xmax -
                      data.data.result[0][i].coordinate.xmin) *
                    reductionFactor;
                  var height =
                    (data.data.result[0][i].coordinate.ymax -
                      data.data.result[0][i].coordinate.ymin) *
                    reductionFactor;
                  var top =
                    data.data.result[0][i].coordinate.ymin * reductionFactor;
                  var left =
                    data.data.result[0][i].coordinate.xmin * reductionFactor;
                  _this.$set(data.data.result[0][i], 'width', width);
                  _this.$set(data.data.result[0][i], 'height', height);
                  _this.$set(data.data.result[0][i], 'top', top);
                  _this.$set(data.data.result[0][i], 'left', left);
                }
              }
            };

            this.xyPos = true;
            console.log(JSON.stringify(data.data.result[0]));
            // this.resultData = data.data.result[0]
            this.resultData = this.resultDataList[this.imgCurrentActive];

            // 失败图
            this.errImg = false;
            // 正在加载
            this.loadImg = false;
            // 加载完成  可点击切换
            this.prohibitClicking = false;
          }, 1000);
        } else if (data.state === '0x0008') {
          this.$message.error(data.message);
          this.$refs.navheader.logOn();
        } else {
          setTimeout(() => {
            this.urlAdress = '';
            this.imgUrl = '';
            this.brandsClassName = '';
            this.brandsConfidence = '';
            // 成功图
            this.successImg = false;
            // 失败图
            this.errImg = true;
            this.errText = data.message;
            this.loadImg = false;
            // 加载完成  可点击切换
            this.prohibitClicking = false;
          }, 1000);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
/* header */
.core-function,
.application-scene,
.similar-main,
.different-advantage {
  width: 1280px;
  margin: auto;
}

.application-scene_main,
.different-advantage_main {
  margin-top: 80px;
}
/deep/.banner-header {
  padding-bottom: 44.5%;
}
.header-custom {
  width: 100%;
  margin: auto;
  height: 100%;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  background: rgba(16, 0, 41, 1);
  &::before {
    content: '';
    position: absolute;
    width: 400px;
    height: 400px;
    background: 50%
      radial-gradient(
        circle at 50% 50%,
        rgba(108, 56, 224, 0.2),
        transparent 70%
      );
    border-radius: 1000px;
    left: -10%;
    top: -10%;
  }
  &::after {
    content: '';
    position: absolute;
    width: 300px;
    height: 300px;
    background: 50%
      radial-gradient(
        circle at 50% 50%,
        rgba(108, 56, 224, 0.2),
        transparent 70%
      );
    border-radius: 1000px;
    right: -5%;
    bottom: -7%;
  }
  img {
    position: absolute;
  }
  .banner-bg {
    width: 100%;
    top: 0;
    left: 0;
  }
  .banner1 {
    width: 74.4%;
    bottom: 0%;
    left: 50%;
    transform: translateX(-50%);
  }
}

/* 核心 */
.core-function {
  padding: 80px 0 80px;
  .core-scan {
    width: 10%;
    margin-top: 60px;
  }

  .core-function_main {
    margin-top: 68px;
  }
  .core-custom {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 24px;
    align-items: flex-end;
    // margin-top: 107px;
    box-sizing: border-box;
    // overflow: hidden;
    margin-top: -260px;
    img {
      flex-shrink: 0;
    }
    .core-clothes {
      width: 15.2%;
      margin-right: 24px;
    }
    .core-person {
      position: relative;
      width: 31.1%;
      margin: 0 34px 0 14px;
      top: 80px;
    }
    .core-store {
      width: 32%;
    }
    .core-similar {
      position: absolute;
      width: 8.4%;
      top: 0;
      right: 2%;
    }
  }

  /deep/ .common-two-column_img {
    width: 47%;
    margin-top: -109px;
  }
  /deep/ .common-two-column_img {
    margin-right: 93px;
  }
  /deep/ .common-two-column {
    column-gap: 133px;
  }
}

/* 应用场景 */
.application-scene {
  padding-top: 80px;
  padding-bottom: 108px;
  /deep/ .common-two-column {
    column-gap: 24px;
  }
  /deep/ .common-two-column_img {
    width: 49.1%;
    margin-top: -102px;
  }
}

// 能力展示
.similar-main-wrap {
  padding: 80px 0;
  width: 100%;
  background: #f0f2f5;
  /deep/ .common-text {
    img {
      vertical-align: middle;
      display: inline-block;
      margin: -2px 3px 0;
      width: 24px;
    }
  }
  .operationBackgroundColor {
    height: calc(100% - 62px);
  }
  .similarityGraph {
    width: 32.8%;
    background: #d8d8d8;
    border-radius: 8px;
  }
  .operationCenter {
    width: 73.3%;
    height: auto;
    margin-left: 16px;
    img {
      max-width: 100%;
      max-height: 100%;
      width: fit-content;
    }
  }
  .successImgProduct {
    width: 100%;
  }
  .operationYellowRight {
    flex: 1;
    flex-shrink: 0;
    height: 100%;
    margin: 7px 0 0 24px;
  }
  .recognitionResult {
    margin: 0;
    font-size: 18px;
    color: #000108;
    span {
      color: #ff8645;
    }
  }
  .detectionButton {
    background: rgba(108, 56, 224, 0.1);
    color: rgba(108, 56, 224, 1);
  }
  .confidenceLevel {
    margin: 0;
    font-size: 14px;
    line-height: 22px;
  }
  .similarityGraphMain {
    height: 100%;
    justify-content: space-between;
    row-gap: 0.8%;
    column-gap: 0.8%;
  }
  .similarityGraphImg img {
    border-radius: 8px;
    overflow: hidden;
  }
}
.similar-main-cont {
  // display: flex;
  width: 100%;
  // height: 770px;
  margin: auto;
  background-color: #fff;
  padding: 16px;
  border-radius: 16px;
  box-sizing: border-box;
}

@media screen and(max-width: 1280px) {
  .core-function {
    .core-custom {
      margin-top: 0;
    }
  }
  // 能力展示
  .similar-main-cont {
    height: fit-content;
    .similarityGraphMain {
      justify-content: flex-start;
    }
    .similarityGraph {
      padding-bottom: 25px;
      height: auto;
      img {
        max-width: 90%;
        max-height: 90%;
      }
    }
    .operationRight {
      margin-left: 0;
    }
    .operationCenter {
      margin: 20px 0;
      width: 100%;
      min-height: 400px;
      margin-left: 0;
    }
  }
}
@media screen and(max-width: 990px) {
  .core-function {
    /deep/.common-two-column {
      margin-top: 40px;
    }
    .core-scan {
      width: 110px;
      margin-top: 30px;
    }
  }
}
@media screen and (min-width: 1440px) {
  .banner-imgs {
    position: absolute;
    height: 100%;
    width: 1440px;
    margin: auto;
    left: 50%;
    z-index: 1;
    transform: translateX(-50%);
    // bottom: -0;
  }
  .header-custom {
    // background-color: #100029;
    background: rgba(16, 0, 41, 1);
    .banner-bg {
      width: 1440px;
      height: 880px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  /deep/.banner-header {
    padding-bottom: 724px;
    margin-bottom: 78px;
    background: transparent;
  }
}
</style>
