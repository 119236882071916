<!--
 * @Date: 2023-11-02 14:36:07
 * @LastEditTime: 2024-02-23 18:54:33
 * @Description: 公用上传列表-除1024外的二级页面
-->
<template>
  <div :class="['img-upload', directionRowClass]">
    <!-- <div class="img-upload-title">
        <slot name="title"><img v-if="titleIcon" :src="titleIcon" width="25px" height="28px" />{{
          title
        }}</slot>
      </div> -->
    <div
      class="logoExample"
      ref="logoExample"
      :class="{
        prohibitClicking: prohibitClicking == true,
        'full-height': !showUpload,
      }"
    >
      <div class="logoExample-wrap" ref="logoList" v-if="imgList.length">
        <div
          class="logoExampleMain"
          v-for="(item, index) in imgList"
          :key="index"
          @click="selectPicture(index, item)"
          :class="{ imgActive: index === modelIndex }"
        >
          <img :src="item.img" />
        </div>
      </div>
    </div>
    <!-- <ElPopover
        placement="right-start"
        width="640"
        :disabled="!tipsShow"
        trigger="hover"
      >
        <img
          style="width: 604px; height: 488px"
          src="../../assets/img/1024/tips.png"
        /> -->
    <template v-if="showUpload">
      <el-upload
        slot="reference"
        :action="action"
        class="imgUpload"
        list-type="picture-card"
        :multiple="multiple"
        :show-file-list="false"
        :headers="headers"
        :accept="accept"
        :file-list="fileList"
        :on-remove="handleRemove"
        :on-change="handleChange"
        :on-success="success"
        :before-upload="changeFile"
      >
        <!-- :http-request="changeFile" -->
        <!-- :auto-upload="autoUpload" -->

        <div class="upload-img">
          <!-- <img type="default" src="@/assets/img/1024/uploadimg.png" alt=""><img type="hover" src="@/assets/img/1024/uploadimg_hover.png" alt=""> -->
          <p>{{ uploadText }}</p>
        </div>
      </el-upload>
    </template>
    <!-- </ElPopover> -->

    <!-- <div class="tap-slide tap-up" @click="handleSlide('up')">
        <img src="@/assets/img/1024/tap_up.png" alt="上翻" />
      </div> -->

    <!-- <div class="tap-slide tap-down" @click="handleSlide('down')">
        <img src="@/assets/img/1024/tap_down.png" alt="下翻" />
      </div> -->
  </div>
</template>

<script>
import { apiUri } from '@/api/txt';
export default {
  name: 'ImgUpload',
  props: {
    // 图片列表
    imgList: {
      type: Array,
      default: () => [],
    },
    // 标题
    title: '',
    // 指定索引
    modelIndex: {
      type: Number,
      default: -2,
    },
    // 上传是否多选
    multiple: {
      type: Boolean,
      default: false,
    },
    // 滚动列表至顶部
    listScrollTop: {
      type: Boolean,
      default: false,
    },
    // 上传框文字
    uploadText: {
      type: String,
      default: '上传图片',
    },
    titleIcon: {
      type: String,
      default: '',
    },
    // 是否展示文字提示
    tipsShow: {
      type: Boolean,
      default: false,
    },
    // 上传的文件类型
    accept: {
      type: String,
      default: 'image/jpg,image/png,image/jpeg',
    },
    // 上传地址
    action: {
      type: String,
      default: `${apiUri}/upload/uploadFile`,
    },
    // 是否展示上传按钮
    showUpload: {
      type: Boolean,
      default: true,
    },
    // 是否开启1280尺寸下横向排列
    directionRow1280: {
      type: Boolean,
      default: false,
    },
    // 是否开启自动上传
    autoUpload: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    // fileList: {
    //   get() {
    //     return this.imgList
    //   },
    // 1280尺寸下横向排列
    directionRowClass(val) {
      return this.directionRow1280 ? 'direction-row' : '';
    },
    // }
  },
  watch: {
    /**
     * @description: 列表是否滚动至指定位置
     * @param {*} val
     * @return {*}
     */
    listScrollTop(val) {
      if (val) {
        this.scrollDistance = 0;
        this.$refs.logoExample.scrollTo({
          top: this.scrollDistance,
          left: 0,
          behavior: 'smooth',
        });
        // this.$refs.logoList.style.transform=`translateY(${this.scrollDistance}px)`;
        this.$nextTick(() => {
          this.$emit('update:listScrollTop', false);
        });
      }
    },
  },
  data() {
    return {
      headers: {
        token: localStorage.getItem('tokenKey')
          ? localStorage.getItem('tokenKey')
          : '',
      },
      // action:`${apiUri}/img/imgUpload`, // 老的图片上传接口
      fileList: this.imgList,
      // 加载时，禁止点击
      prohibitClicking: false,
      // 当前选中的图片
      imgCurrentActive: -2,
      // 图片列表滚动距离
      scrollDistance: 0,
    };
  },
  methods: {
    /**
     * @description: 上传选择图片触发
     * @param {*} files
     * @return {*}
     */
    changeFile(files) {
      // this.$message.info('暂不支持自定义上传');
      if (this.autoUpload) {
        // return false;
        return true;
      } else {
        this.$emit('httpRequest', files);
        // return false;
      }
    },

    /**
     * @description: 选择图片
     * @param {*} index
     * @param {*} item
     * @return {*}
     */
    selectPicture(index, item) {
      this.$emit('select', index, item);
    },

    /**
     * @description: 上传文件发生改变时
     * @param {*} file
     * @param {*} fileList
     * @return {*}
     */
    handleChange(file, fileList) {
      this.$emit('change', file, fileList);
    },

    /**
     * @description: 上传成功触发
     * @param {*} response
     * @param {*} file
     * @param {*} fileList
     * @return {*}
     */
    success(response, file, fileList) {
      // console.log('response', response);
      if (response.state === '0x0021') {
        this.$message({
          message: response.message,
          type: 'warning',
          duration: 1 * 1000,
          onClose: () => {
            this.$router.push({
              path: '/login/relevance',
              query: { redirect: window.location.href },
            });
            return;
          },
        });
      }
      this.$emit('success', response, file, fileList);
    },

    /**
     * @description: 文件列表移除文件时的钩子
     * @param {*} file
     * @param {*} fileList
     * @return {*}
     */
    handleRemove(file, fileList) {
      this.$emit('remove', file, fileList);
    },

    /**
     * @description: 图片列表滑动
     * @param {*} action
     * @return {*}
     */
    handleSlide(action) {
      const unitHeight = 142 * 4; // 一次滑动一屏
      let temp = this.$refs.logoExample.clientHeight;
      const maxDistance = this.$refs.logoList.scrollHeight - temp;
      if (maxDistance < 0) return;
      console.log(maxDistance, temp);
      if (action == 'up') {
        // const temp = this.scrollDistance - unitHeight;
        this.scrollDistance =
          this.scrollDistance - temp > 0 ? this.scrollDistance - temp : 0;
        this.$refs.logoExample.scrollTo({
          top: this.scrollDistance,
          left: 0,
          behavior: 'smooth',
        });
        // this.$refs.logoList.style.transform = `translateY(${this.scrollDistance}px)`;
      } else if (action == 'down') {
        // const temp = this.scrollDistance + unitHeight;
        this.scrollDistance =
          this.scrollDistance + temp > maxDistance
            ? maxDistance
            : this.scrollDistance + temp;
        console.log(this.scrollDistance);
        this.$refs.logoExample.scrollTo({
          top: this.scrollDistance,
          left: 0,
          behavior: 'smooth',
        });
        // this.$refs.logoList.style.transform = `translateY(${this.scrollDistance}px)`;
      }
    },
  },
};
</script>

<style lang="less">
* {
  margin: 0;
}
.img-upload {
  position: relative;
  height: 100%;
  transform: translateY();
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  .img-upload-title {
    color: #000108;
    font-size: 20px;
    padding-bottom: 9px;
    height: 37px;
    box-sizing: border-box;
  }
  .img-upload-title img {
    vertical-align: top;
  }

  .logoExample {
    max-height: calc(100% - 100px);
    overflow-y: auto;
    margin-bottom: 8px;
  }
  .full-height {
    max-height: 100%;
  }
  .logoExample-wrap {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    transition: transform 1s;
  }

  .logoExampleMain {
    flex-shrink: 0;
    width: 130px;
    height: 130px;
    line-height: 130px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    background-color: #fff;
    position: relative;
    box-sizing: border-box;
    border: 1.5px solid #fff;
  }

  .logoExampleMain img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 100%;
    max-width: 100%;
    cursor: pointer;
  }
  .tap-slide {
    width: 100%;
    padding: 9px 0 8px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s;
  }
  .tap-slide:hover {
    background: rgba(255, 255, 255, 0.8);
    border-radius: 6px;
  }
  .tap-slide img {
    height: 7px;
    width: 14px;
  }
  .tap-up {
    margin-bottom: 12px;
  }
  .tap-down {
    position: absolute;
    bottom: -36px;
  }
  .upload-img {
    display: flex;
    flex-direction: column;
    flex-basis: 50px;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: #000108;
    cursor: pointer;
  }
  .upload-img img {
    width: 24px;
    height: 20px;
    margin-bottom: 5px;
  }
  .upload-img p {
    /* width: 100%; */
    line-height: 20px;
  }
  .upload-img img[type='hover'] {
    display: none;
  }
  .imgActive {
    border-color: #6c38e0;
  }
  .imgUpload {
    width: 130px;
    height: 130px;
  }

  .imgUpload .el-upload--picture-card {
    width: 130px;
    height: 130px;
    line-height: 130px;
    background-color: transparent;
    border-color: #5d5f6c;
  }
  .imgUpload .el-upload--picture-card:hover {
    border-color: #000;
  }
  .imgUpload .el-upload--picture-card:hover .upload-img {
    color: #000108;
  }

  .imgUpload .el-upload--picture-card:hover .upload-img img {
    display: none;
  }
  .imgUpload .el-upload--picture-card:hover .upload-img img[type='hover'] {
    display: block;
  }
  .imgUpload .el-upload--picture-card:hover .upload-img img {
    width: 24px;
    height: 20px;
    margin-bottom: 5px;
  }
}

@media screen and (max-width: 1280px) {
  // 横向展示
  .img-upload.direction-row {
    flex-direction: row;
    width: 100%;
    height: auto;
    .logoExample {
      max-height: fit-content;
      max-width: calc(100% - 113px);
      overflow-y: auto;
      margin-bottom: 0;
      padding-bottom: 0;
    }
    .logoExample-wrap {
      flex-direction: row;
      column-gap: 8px;
      row-gap: 0;
      margin-right: 8px;
      overflow-x: auto;
      width: 100%;
      padding-bottom: 0;
      max-height: fit-content;
    }
  }
}

::-webkit-scrollbar {
  width: 0px; /* 设置滚动条宽度 */
  height: 0;
}
</style>
